import { variables } from '@indriver/yrel';
import { HintOutline } from '@indriver/mireska';
import React, { useState } from 'react';
import { Hint, HintWrapper } from 'widgets/order-form/ui/order-form.style';
import Text from 'shared/ui/text/text';
import { motion } from 'framer-motion';
import { useIsRTL } from 'shared/hooks';

const variants = {
    initial: { opacity: 0 },
    open: { opacity: 1 },
    closed: { opacity: 0 },
};
export const HintRadioButton = () => {
    const [visible, setVisible] = useState(false);
    const { direction } = useIsRTL();

    return (
        <HintWrapper
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
            onMouseEnter={(e) => {
                e.stopPropagation();
                setVisible(true);
            }}
            onMouseLeave={(e) => {
                e.stopPropagation();
                setVisible(false);
            }}>
            <div className='hint-icon'>
                <HintOutline dir={direction} size={24} color={variables['text-and-icon-secondary']} />
            </div>

            <motion.div
                transition={{ duration: 0.3 }}
                initial={'initial'}
                animate={visible ? 'open' : 'closed'}
                variants={variants}
                className={Hint}>
                <Text size='s' as='div' text='order.with_fellow_hint_text' />
                <Text size='s' as='div' text='order.without_fellow_hint_text' />
            </motion.div>
        </HintWrapper>
    );
};
