import { CounterBtn, InputCounterWrapper } from 'widgets/order-form/ui/order-form.style';
import { FC, RefObject, useCallback } from 'react';
import Button from 'shared/ui/button/button';
import Text from 'shared/ui/text/text';
import { Add, Minus } from '@indriver/mireska';

interface IProps {
    value: number;
    visible: boolean;
    onClickPlus: (newValue: number) => void;
    onClickMinus: (newValue: number) => void;
    divRef: RefObject<HTMLDivElement>;
    dir: 'ltr' | 'rtl';
}

export type Ref = HTMLDivElement;

export const InputCounter: FC<IProps> = ({ divRef, visible, value, onClickPlus, onClickMinus, dir = 'ltr' }) => {
    const handleCLickPlus = useCallback(() => {
        if (value <= 50) {
            onClickPlus(value + 1);
        }
    }, [onClickPlus]);

    const handleCLickMinus = useCallback(() => {
        if (value > 1) {
            onClickMinus(value - 1);
        }
    }, [onClickMinus]);
    return (
        <InputCounterWrapper ref={divRef} style={{ display: visible ? 'flex' : 'none' }}>
            <Button
                dir={dir}
                size='s'
                disabled={value === 1}
                design='secondary'
                className={CounterBtn}
                postfix={<Minus size={20} />}
                onClick={handleCLickMinus}
            />
            <Text as='span' size='l'>
                {value}
            </Text>
            <Button dir={dir} size='s' className={CounterBtn} postfix={<Add size={20} />} onClick={handleCLickPlus} />
        </InputCounterWrapper>
    );
};
